const electricPriceAdapter = (electricPrice) => {
  let electricPricesAdapted = {
    id: electricPrice.id,
    dhType: electricPrice.dh_type,
    energyPrices: [
      electricPrice.energy_price_p1,
      electricPrice.energy_price_p2,
      electricPrice.energy_price_p3,
      electricPrice.energy_price_p4,
      electricPrice.energy_price_p5,
      electricPrice.energy_price_p6,
    ],
    energyDiscounts: [
      electricPrice.discount_energy_p1,
      electricPrice.discount_energy_p2,
      electricPrice.discount_energy_p3,
      electricPrice.discount_energy_p4,
      electricPrice.discount_energy_p5,
      electricPrice.discount_energy_p6,
    ],
    powerPrices: [
      electricPrice.power_price_p1,
      electricPrice.power_price_p2,
      electricPrice.power_price_p3,
      electricPrice.power_price_p4,
      electricPrice.power_price_p5,
      electricPrice.power_price_p6,
    ],
    powerDiscounts: [
      electricPrice.discount_power,
      electricPrice.discount_power_p2,
    ],
  };
  return electricPricesAdapted;
};

export default electricPriceAdapter;
